<lib-spinner [showSpinner]="isLoading" [fullPage]="false"></lib-spinner>
<ng-container [ngSwitch]="formState">
  <!-- INTAKE -->
  <ng-container *ngSwitchCase="'intake'">
    <!-- REP -->
    <div *ngIf="case && isRep">
      <ng-container *ngIf="isEditing">
        <ng-container
          [ngTemplateOutlet]="clientEditFormTemplate"
        ></ng-container>
        <ng-container [ngTemplateOutlet]="lobInfo"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isEditing">
        <ng-container [ngTemplateOutlet]="clientInfo"></ng-container>
      </ng-container>
    </div>
    <!-- CLIENT -->
    <div *ngIf="case && isClient">
      <ng-container [ngTemplateOutlet]="lobInfo"></ng-container>
    </div>
  </ng-container>

  <!-- NEW APPLICATION -->
  <lib-spinner [showSpinner]="isLoading" [fullPage]="false"></lib-spinner>
  <ng-container *ngSwitchCase="'new'">
    <h2>
      {{ "INTAKE.PROGRAM_SELECTION_FORM.HEADING" | translate }}
    </h2>
    <!-- REP -->
    <ng-container *ngIf="case === undefined && isRep">
      <form [formGroup]="programSelectionForm" autocomplete="off">
        <ng-container [ngTemplateOutlet]="lobSelector"></ng-container>
        <ng-container *ngIf="newCase.lobId && programSelectionForm.valid">
          <!-- PSR form -->
          <ng-container
            [ngTemplateOutlet]="primaryContactInvite"
            *ngIf="isPSR"
          ></ng-container>
          <!-- default form -->
          <ng-container
            [ngTemplateOutlet]="clientEditFormTemplate"
            *ngIf="!isPSR"
          ></ng-container>
        </ng-container>
        <ng-container [ngTemplateOutlet]="personalize"> </ng-container>
        <ng-container [ngTemplateOutlet]="submit"></ng-container>
      </form>
    </ng-container>
    <!-- CLIENT -->
    <ng-container
      *ngIf="case === undefined && isClient && !primarySponsorStepper"
    >
      <form [formGroup]="programSelectionForm" autocomplete="off">
        <!-- PSR primary sponsor form -->
        <ng-container [ngTemplateOutlet]="lobSelector"></ng-container>
        <ng-container *ngIf="isPSR">
          <ng-container [ngTemplateOutlet]="personalize"> </ng-container>
          <ng-container [ngTemplateOutlet]="showStepper"></ng-container>
        </ng-container>
        <!-- GAR referral partner form -->
        <ng-container *ngIf="isGAR">
          <ng-container
            [ngTemplateOutlet]="garReferralFormTemplate"
          ></ng-container>
          <ng-container [ngTemplateOutlet]="personalize"> </ng-container>
          <ng-container [ngTemplateOutlet]="submit"></ng-container>
        </ng-container>
        <!-- default client email form -->
        <ng-container *ngIf="!isPSR && !isGAR">
          <ng-container [ngTemplateOutlet]="personalize"> </ng-container>
          <ng-container [ngTemplateOutlet]="submit"></ng-container>
        </ng-container>
      </form>
    </ng-container>
    <div *ngIf="primarySponsorStepper">
      <ng-container
        [ngTemplateOutlet]="primarySponsorStepperTemplate"
      ></ng-container>
    </div>
  </ng-container>
</ng-container>

<!-- modular templates  -->

<ng-template #clientInfo>
  <ng-container>
    <h5>
      <strong> {{ "INTAKE.CLIENT_EDIT_FORM.HEADING" | translate }}</strong>
    </h5>
    <ul class="list-unstyled">
      <li>
        <strong
          >{{ "INTAKE.PROFILE.DEPENDENT_SECTION.NAME" | translate
          }}{{ "COLON" | translate }}</strong
        >
        {{ clientGivenName }} {{ clientFamilyName }}
      </li>
      <li>
        <strong
          >{{
            "INTAKE.IMM0008.FORMS.ADDRESS.CONTACT_EMAIL.EMAIL_LABEL"
              | translate
          }}{{ "COLON" | translate }}</strong
        >
        {{ clientEmail }}
      </li>
    </ul>

    <lib-layout-row gap="one-and-half" mt="2" mb="2">
      <!-- button for updating the email-->
      <button type="button" category="secondary" (click)="toggleEditing()">
        {{ "INTAKE.PROGRAM_SELECTION_FORM.BUTTON_EDIT" | translate }}
      </button>
      <!-- Resend password button -->
      <button
        type="button"
        category="plain"
        attr.aria-describedby="{{
          canResetPassword ? 'helpTextEnabled' : 'helpTextDisabled'
        }}"
        (click)="resendPassword()"
        [disabled]="!canResetPassword"
      >
        {{ "INTAKE.PROGRAM_SELECTION_FORM.RESEND_TEMP_PASSWORD" | translate }}
      </button>
    </lib-layout-row>
    <p *ngIf="emailSent" class="help-text" id="helpTextEnabled">
      {{
        "INTAKE.PROGRAM_SELECTION_FORM.RESEND_TEMP_PASSWORD_SENT" | translate
      }}
    </p>
    <p *ngIf="canResetPassword" class="help-text" id="helpTextEnabled">
      {{ "INTAKE.PROGRAM_SELECTION_FORM.RESEND_HELP_TEXT_ENABLED" | translate }}
    </p>
    <p
      *ngIf="!canResetPassword && !emailSent"
      class="help-text"
      id="helpTextDisabled"
    >
      {{
        "INTAKE.PROGRAM_SELECTION_FORM.RESEND_HELP_TEXT_DISABLED" | translate
      }}
    </p>
  </ng-container>
  <ng-container [ngTemplateOutlet]="lobInfo"> </ng-container>
</ng-template>

<ng-template #lobInfo>
  <h5 class="text-bold">
    {{ "INTAKE.PROGRAM_SELECTION_FORM.APP_DETAILS" | translate }}
  </h5>
  <ul class="list-unstyled">
    <li>
      <strong>{{
        "INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.CATEGORY_LABEL" | translate
      }}</strong>
      {{ category }}
    </li>
    <li *ngIf="showSubcategoryDropdown">
      <strong>{{
        "INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.SUBCATEGORY_LABEL"
          | translate
      }}</strong>
      {{ subcategory }}
    </li>
    <li *ngIf="applicationName?.length > 0">
      <strong>{{
        "INTAKE.PROGRAM_SELECTION_FORM.PERSONALIZE_SET.NAME_LABEL" | translate
      }}</strong>
      {{ applicationName }}
    </li>
  </ul>
</ng-template>

<ng-template #lobSelector>
  <!-- program selection only container -->
  <ng-container>
    <fieldset *ngIf="!isLoading">
      <legend>
        {{ "INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.LEGEND" | translate }}
      </legend>
      <lib-form-select
        identifier="lobProgram"
        required="true"
        [formControl]="programSelectionForm.controls.program"
        [label]="
          'INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.PROGRAM_LABEL' | translate
        "
        [placeholder]="
          'INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.PROGRAM_PLACEHOLDER'
            | translate
        "
        [options]="programs"
      >
        <span
          *ngIf="
            programSelectionForm.get('program')?.touched &&
            programSelectionForm.get('program')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
      <lib-form-select
        *ngIf="categories"
        identifier="lobCategory"
        required="true"
        [formControl]="programSelectionForm.controls.category"
        [label]="
          'INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.CATEGORY_LABEL' | translate
        "
        [placeholder]="
          'INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.CATEGORY_PLACEHOLDER'
            | translate
        "
        [options]="categories"
      >
        <span
          *ngIf="
            programSelectionForm.get('category')?.touched &&
            programSelectionForm.get('category')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="programSelectionForm.get('category')?.hasError('invalidLob')"
        >
          {{
            "INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.INACTIVE_LOB" | translate
          }}
        </span>
      </lib-form-select>

      <lib-form-select
        identifier="lobSubcategory"
        required="true"
        [formControl]="programSelectionForm.controls.subcategory"
        *ngIf="
          programSelectionForm.controls.category.value === 1000 ||
          programSelectionForm.controls.category.value === 1001 ||
          programSelectionForm.controls.category.value === 1003 ||
          programSelectionForm.controls.category.value === 1004 ||
          programSelectionForm.controls.category.value === 17 ||
          programSelectionForm.controls.category.value === 18
        "
        [label]="
          'INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.SUBCATEGORY_LABEL'
            | translate
        "
        [placeholder]="
          'INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.SUBCATEGORY_PLACEHOLDER'
            | translate
        "
        [options]="subcategories"
      >
        <span
          *ngIf="
            programSelectionForm.get('subcategory')?.touched &&
            programSelectionForm.get('subcategory')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            programSelectionForm.get('subcategory')?.hasError('invalidLob')
          "
        >
          {{
            "INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.INACTIVE_LOB" | translate
          }}
        </span>
      </lib-form-select>

      <!-- Private Sponsorship of Refugees (PSR)  -->
      <lib-form-select
        identifier="lobSubcategory"
        required="true"
        [formControl]="programSelectionForm.controls.subcategory"
        *ngIf="programSelectionForm.controls.category.value === 1002"
        [label]="
          'INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.SUBCATEGORY_LABEL_PSR'
            | translate
        "
        [placeholder]="
          'INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.SUBCATEGORY_PLACEHOLDER_PSR'
            | translate
        "
        [options]="subcategories"
      >
        <span
          *ngIf="
            programSelectionForm.get('subcategory')?.touched &&
            programSelectionForm.get('subcategory')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            programSelectionForm.get('subcategory')?.hasError('invalidLob')
          "
        >
          {{
            "INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.INACTIVE_LOB" | translate
          }}
        </span>
      </lib-form-select>
    </fieldset>
  </ng-container>
</ng-template>
<ng-template #clientEditFormTemplate>
  <h2 class="emphasis">
    {{ "INTAKE.CLIENT_EDIT_FORM.HEADING" | translate }}
  </h2>
  <p>
    {{ "INTAKE.CLIENT_EDIT_FORM.CLIENT_SET.FORM_LEGEND" | translate }}
  </p>
  <form [formGroup]="clientEditForm">
    <fieldset>
      <lib-form-input
        identifier="clientEmail"
        type="email"
        [formControl]="clientEditForm.controls.clientEmail"
        [label]="'INTAKE.CLIENT_EDIT_FORM.CLIENT_SET.EMAIL' | translate"
        required="true"
      >
        <span
          *ngIf="
            clientEditForm.get('clientEmail')?.touched &&
            clientEditForm.get('clientEmail')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            clientEditForm.controls.clientEmail.touched &&
            clientEditForm.controls.clientEmail.errors?.email
          "
        >
          {{ "LOGIN.ERRORS.INVALID_EMAIL" | translate }}
        </span>
        <span
          *ngIf="
            clientEditForm.controls.clientEmail.touched &&
            clientEditForm.get('clientEmail')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 100 } }}
        </span>
        <span
          *ngIf="
            clientEditForm.get('clientEmail')?.touched &&
            clientEditForm
              .get('clientEmail')
              ?.hasError('clientEmailMatchRepEmail')
          "
        >
          {{ "INTAKE.FORM_ERRORS.CLIENT_USER_EMAIL_MATCH" | translate }}
        </span>
      </lib-form-input>

      <lib-form-input
        identifier="clientEmailVerified"
        type="email"
        [formControl]="clientEditForm.controls.clientEmailVerified"
        [label]="'INTAKE.CLIENT_EDIT_FORM.CLIENT_SET.CONFIRM_EMAIL' | translate"
        [errors]="verifyEmailErrors"
        required="true"
      >
        <span
          *ngIf="
            clientEditForm.get('clientEmailVerified')?.touched &&
            clientEditForm.get('clientEmailVerified')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            clientEditForm.get('clientEmailVerified')?.touched &&
            clientEditForm.get('clientEmail')?.touched &&
            clientEditForm.get('clientEmailVerified')?.hasError('matches')
          "
        >
          {{ "INTAKE.FORM_ERRORS.EMAIL_MATCH" | translate }}
        </span>
      </lib-form-input>
    </fieldset>
    <!-- buttons when editing case -->
    <div *ngIf="isEditing" class="form__buttons">
      <lib-layout-row gap="one-and-half">
        <button
          type="button"
          [disabled]="isClientEmailFormValid"
          (click)="updateCase()"
        >
          {{ "INTAKE.PROGRAM_SELECTION_FORM.BUTTON_SAVE" | translate }}
        </button>
        <button category="secondary" (click)="cancelUpdate()">
          {{ "INTAKE.PROGRAM_SELECTION_FORM.BUTTON_CANCEL" | translate }}
        </button>
      </lib-layout-row>
    </div>
  </form>
</ng-template>
<ng-template #primarySponsorStepperTemplate>
  <div class="sponsor-profile">
    <lib-layout-row>
      <header>
        <h1>
          {{ "PSR.SPONSOR_PROFILE_PAGE.PAGE_HEADING" | translate }}
        </h1>
        <p>
          {{ "PSR.SPONSOR_PROFILE_PAGE.FORM_DESCRIPTION" | translate }}
        </p>
      </header>
    </lib-layout-row>
    <form focusInvalidInput [formGroup]="sponsorProfileForm" autocomplete="off">
      <fieldset>
        <lib-form-input
          identifier="familyName"
          [formControl]="sponsorProfileForm.controls.familyName"
          [label]="
            'PSR.SPONSOR_PROFILE_PAGE.FORM.FAMILY_NAME_OR_ORGANIZATION'
              | translate
          "
          required="true"
        >
          <span
            *ngIf="
              sponsorProfileForm.controls?.familyName.touched &&
              sponsorProfileForm.controls?.familyName.hasError('required')
            "
            >{{ "PSR.MESSAGES.REQUIRED" | translate }}
          </span>
        </lib-form-input>
        <lib-form-input
          identifier="givenName"
          [formControl]="sponsorProfileForm.controls.givenName"
          [label]="'PSR.SPONSOR_PROFILE_PAGE.FORM.GIVEN_NAME' | translate"
        >
        </lib-form-input>
        <lib-information-box
          [config]="stepperInformationBoxConfig"
          *ngIf="sponsorProfileForm.valid"
        >
          <p>
            {{
              "INTAKE.PROGRAM_SELECTION_FORM.PRIMARY_CONTACT_CONFIRMATION"
                | translate
            }}
          </p>
        </lib-information-box>
        <pra-form-buttons [form]="sponsorProfileForm">
          <div class="sponsor-profile__buttons">
            <button
              type="submit"
              [disabled]="sponsorProfileForm.invalid || isSubmitting"
              category="primary"
              role="link"
              (click)="onSubmit()"
            >
              {{ "INTAKE.PROGRAM_SELECTION_FORM.SUBMIT" | translate }}
            </button>
          </div>
        </pra-form-buttons>
      </fieldset>
    </form>
  </div>
</ng-template>

<ng-template #primaryContactInvite>
  <pra-invite-group-members
    [isPrimaryContact]="true"
    (childForm)="setPrimaryContactForm($event)"
  ></pra-invite-group-members>
  <lib-information-box
    [config]="stepperInformationBoxConfig"
    *ngIf="primaryContactForm?.valid && isRep && isPSR"
  >
    <p>
      {{
        "INTAKE.PROGRAM_SELECTION_FORM.PRIMARY_CONTACT_CONFIRMATION" | translate
      }}
    </p>
  </lib-information-box>
</ng-template>

<ng-template #garReferralFormTemplate>
  <section class="referral-partner-form">
    <h2>
      {{ "GAR.REFERRAL_PARTNER_FORM.HEADING" | translate }}
    </h2>
    <form>
      <fieldset>
        <legend>
          {{ "GAR.REFERRAL_PARTNER_FORM.FORM_DESCRIPTION" | translate }}
        </legend>
        <lib-form-radio
          [legend]="'GAR.REFERRAL_PARTNER_FORM.REFERRAL_PARTNER' | translate"
          firstId="hasRefYes"
          secondId="hasRefNo"
          [firstValue]="true"
          [secondValue]="false"
          [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
          [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
          nameVal="dependantDetailsForm-accompanyingPA"
          [formControl]="garReferralForm.controls.isReferralPartner"
          [required]="true"
        >
          <span
            class="error-radio"
            *ngIf="
              garReferralForm.get('isReferralPartner')?.touched &&
              garReferralForm.get('isReferralPartner')?.hasError('required')
            "
          >
            {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-radio>
        <ng-container
          *ngIf="garReferralForm.controls.isReferralPartner.value === true"
        >
          <lib-form-input
            type="text"
            identifier="referralPartnerFamilyName"
            [formControl]="garReferralForm.controls.familyName"
            required="true"
            [label]="'GAR.REFERRAL_PARTNER_FORM.FAMILY_NAME' | translate"
          >
            <span
              *ngIf="
                garReferralForm.get('familyName')?.touched &&
                garReferralForm.get('familyName')?.hasError('required')
              "
            >
              {{ "FORM_ERRORS.REQUIRED" | translate }}
            </span>
          </lib-form-input>
          <lib-form-input
            type="text"
            identifier="referralPartnerGivenName"
            [formControl]="garReferralForm.controls.givenName"
            [label]="'GAR.REFERRAL_PARTNER_FORM.GIVEN_NAME' | translate"
          >
          </lib-form-input>
          <lib-form-select
            identifier="referralPartner"
            required="true"
            [formControl]="garReferralForm.controls.referralOrganization"
            [label]="
              'GAR.REFERRAL_PARTNER_FORM.REFERRAL_PARTNER_ORGANIZATION'
                | translate
            "
            [options]="sortReferralOrganization"
            [placeholder]="
              'GAR.REFERRAL_PARTNER_FORM.REFERRAL_PARTNER_ORGANIZATION'
                | translate
            "
          >
            <span
              *ngIf="
                garReferralForm.get('referralOrganization')?.touched &&
                garReferralForm
                  .get('referralOrganization')
                  ?.hasError('required')
              "
            >
              {{ "FORM_ERRORS.REQUIRED" | translate }}
            </span>
          </lib-form-select>
          <div class="referral-partner-form__warning">
            <ircc-cl-lib-banner [config]="garBannerConfig"></ircc-cl-lib-banner>
          </div>
        </ng-container>
        <ng-container
          *ngIf="garReferralForm.controls.isReferralPartner.value === false"
        >
          <ircc-cl-lib-banner
            [config]="garNoReferralPartnerBannerConfig"
          ></ircc-cl-lib-banner>
        </ng-container>
      </fieldset>
    </form>
  </section>
</ng-template>

<ng-template #submit>
  <button
    type="button"
    [disabled]="isFormInvalid || isSubmitting"
    (click)="onSubmit()"
  >
    {{ "INTAKE.PROGRAM_SELECTION_FORM.SUBMIT" | translate }}
  </button>
</ng-template>

<ng-template #showStepper>
  <button
    type="button"
    [disabled]="isFormInvalid || isSubmitting"
    (click)="showPrimarySponsorStepper(true)"
  >
    {{ "INTAKE.PROGRAM_SELECTION_FORM.SUBMIT" | translate }}
  </button>
</ng-template>

<ng-template #personalize>
  <fieldset>
    <legend>
      {{ "INTAKE.PROGRAM_SELECTION_FORM.PERSONALIZE_SET.LEGEND" | translate }}
    </legend>
    <lib-form-input
      type="text"
      identifier="applicationName"
      [formControl]="programSelectionForm.controls.applicationName"
      [label]="
        'INTAKE.PROGRAM_SELECTION_FORM.PERSONALIZE_SET.NAME_LABEL' | translate
      "
      [placeholder]="
        'INTAKE.PROGRAM_SELECTION_FORM.PERSONALIZE_SET.NAME_PLACEHOLDER'
          | translate
      "
    >
    </lib-form-input>
  </fieldset>
</ng-template>
